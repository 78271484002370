<template>
  <div class="py-4 lg:py-8 relative min-h-screen">
    <img v-if="contact_page.bg_image" v-bind:src="contact_page.bg_image" class="h-2/5 lg:h-full w-full lg:w-1/2 absolute inset-0 object-cover object-center xl:block hidden" alt="map" />
    <div class="xl:mx-auto xl:container relative">
      <div class="flex flex-wrap xl:mx-auto xl:container">
        <div class="w-full relative lg:w-1/2 xl:mt-10 mb-10 2xl:pr-24 2xl:pl-0 xl:pl-12 pl-0">
          <img v-if="contact_page.bg_image" v-bind:src="contact_page.bg_image" class="h-full w-full xl:w-1/2 absolute inset-0 bg-cover bg-center xl:hidden" alt="map" />
          <div class="w-full flex flex-col items-start xl:justify-start relative z-20 xl:px-0 px-4 xl:py-0 py-4">
            <div class="w-full 2xl:pl-48 xl:pt-1">
              <h1 class="text-4xl md:text-5xl lg:text-7xl font-bold tracking-wider text-gray-800" v-if="contact_page.title">{{contact_page.title}}</h1>
              <div class="w-full md:w-10/12 mt-3">
                <h2 class="text-gray-800 text-base md:text-lg leading-8 tracking-wider" v-if="contact_page.description" v-html="contact_page.description"></h2>
                <div class="mt-4 md:mt-8"  v-if="contact_page.address">
                  <h2 class="text-sm md:text-base text-indigo-700 font-semibold">Adresa</h2>
                  <h2 class="text-gray-800 text-base md:text-lg leading-8 tracking-wider mt-2">{{ contact_page.address }}</h2>
                </div>
                <div class="mt-4 md:mt-8" v-if="contact_page.contact">
                  <h2 class="text-sm md:text-base text-indigo-700 font-semibold">Telefon</h2>
                  <h2 class="text-gray-800 text-base md:text-lg leading-8 tracking-wider mt-2">{{ contact_page.contact }}</h2>
                </div>
                <div class="mt-4 md:mt-8" v-if="contact_page.email">
                  <h2 class="text-sm md:text-base text-indigo-700 font-semibold">Email</h2>
                  <h2 class="text-gray-800 text-base md:text-lg leading-8 tracking-wider mt-2">{{ contact_page.email }}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/2 xl:pt-10 lg:pl-24">
          <div class="flex flex-col items-start xl:justify-start 2xl:justify-end xl:px-0 px-4">
            <h1 class="text-4xl md:text-5xl lg:text-7xl font-bold tracking-wider text-indigo-700" v-if=" contact_page.contact_title">{{ contact_page.contact_title }}</h1>
            <div class="w-full 2xl:w-8/12 mt-3">
              <h2 class="text-gray-800 text-base md:text-lg leading-8 tracking-wider" v-if="contact_page.contact_desc">{{ contact_page.contact_desc }}</h2>
              <form class="pt-5" @submit.prevent="contact" @keydown="form.onKeydown($event)">
                <div class="bg-green-200 border-t-4 border-green-500 rounded-b text-teal-900 px-4 py-3 mb-3 shadow-md" role="alert" v-if="form.successful">
                  <div class="flex">
                    <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                    <div>
                      <p class="font-bold">Mesazhi juaj u dërgua me sukses</p>
                      <p class="text-sm">Do t'ju kontaktojmë sa më shpejt. Faleminderit!</p>
                    </div>
                  </div>
                </div>
                <div class="mb-4">
                  <label class="block mb-1" for="first_name">Emri</label>
                  <input id="first_name" type="text" v-model="form.first_name" class="form-input" :class="{ error: form.errors.has('first_name') }" />
                  <div v-if="form.errors.has('first_name')" class="form-error" v-html="form.errors.get('first_name')"></div>
                </div>
                <div class="mb-4">
                  <label class="block mb-1" for="last_name">Mbiemri</label>
                  <input id="last_name" type="text" v-model="form.last_name" class="form-input" :class="{ error: form.errors.has('last_name') }" />
                  <div v-if="form.errors.has('last_name')" class="form-error" v-html="form.errors.get('last_name')"></div>
                </div>
                <div class="mb-4">
                  <label class="block mb-1" for="email">Email</label>
                  <input id="email" type="text" v-model="form.email" class="form-input" :class="{ error: form.errors.has('email') }" />
                  <div v-if="form.errors.has('email')" class="form-error" v-html="form.errors.get('email')"></div>
                </div>
                <div class="mb-4">
                  <label class="block mb-1" for="phone">Telefoni</label>
                  <input id="phone" type="text" v-model="form.phone" class="form-input" :class="{ error: form.errors.has('phone') }" />
                  <div v-if="form.errors.has('phone')" class="form-error" v-html="form.errors.get('phone')"></div>
                </div>
                <div class="mb-4">
                  <label class="block mb-1" for="message">Mesazhi</label>
                  <textarea id="message" v-model="form.message" class="form-input" :class="{ error: form.errors.has('message') }">
                  </textarea>
                  <div v-if="form.errors.has('message')" class="form-error" v-html="form.errors.get('message')"></div>
                </div>
                <div class="mt-6">
                  <button :disabled="form.busy" class="w-full inline-flex items-center justify-center px-4 py-2 bg-indigo-900 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-indigo-800 active:bg-indigo-800 focus:outline-none focus:border-indigo-800 focus:ring focus:ring-indigo-600 disabled:opacity-25 transition">Dërgo</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Form from 'vform'
import axios from 'axios';
import {API_URL} from '@/.env';
export default {
  name: "index",
  data() {
    return {
      form: new Form({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
      })
    };
  },
  computed: {
    ...mapGetters('home', ['contact_page']),
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
    this.$store.dispatch('home/getContactPage');
  },
  methods: {
    async contact () {
      // Submit the form.
      const instance = axios.create({
        baseURL: API_URL
      })

      Form.axios = instance

      const { data } = await this.form.post('/api/contact-form')

      this.form.reset()
    }
  }
};
</script>
