<template>
  <div class="container mx-auto" v-if="categories.length">
    <div class="md:flex mt-4 md:-mx-4" >
      <div v-for="category in categories" class="w-full h-96 md:mx-4 rounded-md overflow-hidden bg-cover bg-center md:w-1/2 mt-20" :style="`background-image: url(${category.photo})`">
        <div class="bg-gray-900 bg-opacity-50 flex items-center h-full">
          <div class="px-10 max-w-xl">
            <h2 class="text-2xl text-white font-semibold">{{category.name}}</h2>
            <p class="mt-2 text-gray-400" v-html="category.description"></p>
            <router-link :to="{name:'Shop'}" class="flex items-center mt-4 text-white text-sm uppercase font-medium rounded hover:underline focus:outline-none">
              <span>Bli Tani</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "Categories",

  computed: {
    ...mapGetters('home', ['categories']),
  },

  created() {
    this.$store.dispatch('home/getCategories');
  },

}
</script>

<style scoped>

</style>
