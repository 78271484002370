<template>
  <div class="2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4">
    <p class="font-normal text-sm leading-3 text-indigo-700 hover:text-indigo-800 cursor-pointer pb-2 text-left">{{about_page.title}}</p>
    <div class="flex lg:flex-row flex-col lg:gap-8 sm:gap-10 gap-12">
      <div class="w-full lg:w-6/12"
      v-html="about_page.description">
      </div>
      <div class="w-full lg:w-6/12">
        <img class="lg:block hidden w-full" :src="about_page.image" alt="people discussing on board" />
        <img class="lg:hidden sm:block hidden w-full" :src="about_page.image" alt="people discussing on board" />
        <img class="sm:hidden block w-full" :src="about_page.image" alt="people discussing on board" />
      </div>
    </div>

    <div class="relative mt-24">
      <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
        <div class="z-20 w-12 h-12 bg-gray-800 rounded-full flex justify-center items-center">
          <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg1.svg" alt="flag" />
        </div>

        <img class="z-20" src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg2.svg" alt="note" />

      </div>
      <hr class="z-10 absolute top-2/4 w-full bg-gray-200" />
    </div>
    <div class="grid sm:grid-cols-3 grid-cols-2 sm:gap-8 gap-4">
      <div v-html="about_page.founded">

      </div>
      <div v-html="about_page.month">
      </div>
    </div>

    <div class="flex lg:flex-row flex-col md:gap-14 gap-16 justify-between lg:mt-20 mt-16">
      <div class="w-full lg:w-6/12" v-html="about_page.mission">
      </div>
      <div class="w-full lg:w-6/12">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 lg:gap-12 gap-10">
          <!-- Team Card -->
          <div class="flex p-4 shadow-md">
            <div class="mr-6">
              <img class="mr-6"  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg4.svg" alt="team card" />
            </div>
            <div class="" v-html="about_page.team">
            </div>
          </div>

          <!-- Board Card -->
          <div class="flex p-4 shadow-md">
            <div class="mr-6">
              <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/about-us-3-svg5.svg" alt="board card" />
            </div>
            <div class="" v-html="about_page.board">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'About',
  computed: {
    ...mapGetters('home', ['about_page']),
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
    this.$store.dispatch('home/getAboutPage');
  },
};
</script>
