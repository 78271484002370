<template>
  <main class="main">
    <carousel/>
    <categories/>
    <products/>
  </main>
</template>

<script>
import Carousel from '../components/Carousel';
import Categories from '../components/Categories';
import Icon from '@/components/Icon'
import Products from '../components/Products'
export default {
  name: 'Home',

  components: {
    Carousel,
    Categories,
    Icon,
    Products,
  },

  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
  },

};
</script>
