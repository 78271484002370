<template>
  <div
    v-if="sliders && sliders.length"
    class="w-full relative"
    style="height: 80vh"
  >
    <transition-group
      tag="div"
      :name="transitionName"
      class="slides-group relative"
    >
      <div
        v-if="show"
        :key="current"
        class="w-full absolute top-0 left-0 flex items-center"
        style="height: 80vh"
        :style="`background-image: url('${sliders[current].img}');`"
      >
        <div class="container mx-auto">
          <div class="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
            <p class="text-white text-2xl my-4">
              {{ sliders[current].title }}
            </p>
            <a
              class="text-xl text-white inline-block no-underline border-b border-white leading-relaxed hover:text-gray-400 hover:border-gray-400"
              :href="sliders[current].linku"
            >{{ sliders[current].btn }}</a>
          </div>
        </div>
      </div>
    </transition-group>
    <div
      class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
      aria-label="Previous slide"
      @click="slide(-1)"
    >
      ‹
    </div>
    <div
      class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
      aria-label="Next slide"
      @click="slide(1)"
    >
      ›
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Carousel',
  data: () => ({
    show: true,
    current: 0,
    direction: 1,
    transitionName: 'fade',
  }),
  computed: {
    ...mapGetters('home', ['sliders']),
  },
  created() {
    this.$store.dispatch('home/getSliders');
  },
  methods: {
    slide(dir) {
      this.direction = dir;
      dir === 1
        ? (this.transitionName = 'slide-next')
        : (this.transitionName = 'slide-prev');
      const len = this.sliders.length;
      this.current = (this.current + dir % len + len) % len;
    },
  },
};
</script>
<style >
/* FADE IN */
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter {
  opacity: 0;
}

/* GO TO NEXT SLIDE */
.slide-next-enter-active,
.slide-next-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-next-enter {
  transform: translate(100%);
}
.slide-next-leave-to {
  transform: translate(-100%);
}

/* GO TO PREVIOUS SLIDE */
.slide-prev-enter-active,
.slide-prev-leave-active {
  transition: transform 0.5s ease-in-out;
}
.slide-prev-enter {
  transform: translate(-100%);
}
.slide-prev-leave-to {
  transform: translate(100%);
}
</style>
