<template>
  <main class="flex-1 lg:mt-20">
    <div class="container mx-auto px-6 my-12 text-gray-700">
      <h2 class="text-2xl text-secondary font-bold flex-1 mb-4">{{privacy_page.title}}</h2>
      <div v-html="privacy_page.content"></div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "privacy",
  computed: {
    ...mapGetters('home', ['privacy_page']),
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
    this.$store.dispatch('home/getPrivacyPage');
  },
};
</script>
