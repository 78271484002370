<template>
  <main class="flex-1 lg:mt-20">
    <div class="container mx-auto px-6 my-12 text-gray-700">
      <h2 class="text-2xl text-secondary font-bold flex-1 mb-4">{{terms_page.title}}</h2>
      <div v-html="terms_page.content"></div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: "terms",
  computed: {
    ...mapGetters('home', ['terms_page']),
  },
  created() {
    this.$store.dispatch('home/hideBasketAndAccount');
    this.$store.dispatch('home/getTermsPage');
  },
};
</script>

<style scoped>

</style>
