<template>
  <section class="bg-white pt-8">
    <div class="container mx-auto flex items-center flex-wrap pt-4 pb-12">
      <nav id="store" class="w-full z-30 top-0 px-6 py-1">
        <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">

          <router-link :to="{name:'Shop'}" class="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl ">	Dyqani 	</router-link>

          <div class="flex items-center" id="store-nav-content">

              <svg class="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M7 11H17V13H7zM4 7H20V9H4zM10 15H14V17H10z" />
              </svg>
          </div>
        </div>
      </nav>
       <product v-for="product in products" :key="product.slug" :product="product"></product>
    </div>

  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import Product from '@/components/Product'
import Icon from '@/components/Icon'
export default {
  name: "Products",

  components:{
    Product,
    Icon,
  },

  computed: {
    ...mapGetters('home', ['products']),
  },

  created() {
    this.$store.dispatch('home/getProducts');
  },
}
</script>

<style scoped>

</style>
